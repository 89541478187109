// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-enterprise-js": () => import("./../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-onboarding-github-js": () => import("./../../src/pages/onboarding/github.js" /* webpackChunkName: "component---src-pages-onboarding-github-js" */),
  "component---src-pages-onboarding-slack-js": () => import("./../../src/pages/onboarding/slack.js" /* webpackChunkName: "component---src-pages-onboarding-slack-js" */),
  "component---src-pages-onboarding-success-js": () => import("./../../src/pages/onboarding/success.js" /* webpackChunkName: "component---src-pages-onboarding-success-js" */),
  "component---src-pages-payments-canceled-js": () => import("./../../src/pages/payments/canceled.js" /* webpackChunkName: "component---src-pages-payments-canceled-js" */),
  "component---src-pages-payments-failed-auth-js": () => import("./../../src/pages/payments/failed-auth.js" /* webpackChunkName: "component---src-pages-payments-failed-auth-js" */),
  "component---src-pages-payments-subscribe-envoy-egiwu-js": () => import("./../../src/pages/payments/subscribe/envoy-egiwu.js" /* webpackChunkName: "component---src-pages-payments-subscribe-envoy-egiwu-js" */),
  "component---src-pages-payments-subscribe-js": () => import("./../../src/pages/payments/subscribe.js" /* webpackChunkName: "component---src-pages-payments-subscribe-js" */),
  "component---src-pages-payments-subscribe-microclusters-wefqw-js": () => import("./../../src/pages/payments/subscribe/microclusters-wefqw.js" /* webpackChunkName: "component---src-pages-payments-subscribe-microclusters-wefqw-js" */),
  "component---src-pages-payments-success-js": () => import("./../../src/pages/payments/success.js" /* webpackChunkName: "component---src-pages-payments-success-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-style-guide-js": () => import("./../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-support-js": () => import("./../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-why-toast-js": () => import("./../../src/pages/why-toast.js" /* webpackChunkName: "component---src-pages-why-toast-js" */)
}

